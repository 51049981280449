import React, { forwardRef, useEffect, useState } from "react";

// js
import "../assets/js/main.min.js";

// css
import "../assets/css/main.min.css";
import "../assets/css/custom.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { getProfile, getStatusWiseAgency } from "../store/admin/action.js";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { NavLink as Link } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.js";
import male from "../assets/images/male.png";
import { Toast } from "../util/Toast.js";
import moment from "moment";
import { OPEN_NEW_REDEEM_DIALOG } from "../store/myRedeem/type.js";
import { getBdEarning } from "../store/history/history.action.js";
import { permissionError } from "../util/Alert.js";
import { getAgencyCommission } from "../store/agencyCommission/action.js";
import { getSetting } from "../store/redeem/action.js";
import dayjs from "dayjs";
import DateRangePicker from "react-bootstrap-daterangepicker";
import leftArrow from "../assets/images/leftArrow.svg";

const Admin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const admin = useSelector((state) => state.admin.seller);
  const { agency } = useSelector((state) => state.admin);
  const { setting } = useSelector((state) => state.redeem);
  const { summary } = useSelector((state) => state.history);
  const { agencyCommission } = useSelector((state) => state.agencyCommission);

  const [type, setType] = useState("agency");
  const startAllDate = "1970-01-01";
  const endAllDate = dayjs().format("YYYY-MM-DD");



  const [startDate, setStartDate] = useState(
    dayjs().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    dayjs().endOf("month").format("YYYY-MM-DD")
  );
  const handleApply = (event,picker) => {

    const start = dayjs(picker.startDate).format("YYYY-MM-DD");
    const end = dayjs(picker.endDate).format("YYYY-MM-DD");
    setStartDate(start);
    setEndDate(end);

    console.log("startDate", startDate, "endDate", endDate );
    console.log("picker.startDate", picker.startDate, "picker.endDate", picker.endDate );

  };
  const bdId = localStorage.getItem("bdId");

  useEffect(() => {
    dispatch(getProfile(bdId));
    dispatch(getStatusWiseAgency(bdId, 2));
    dispatch(getAgencyCommission());
    dispatch(getSetting());
  }, [bdId]);

  useEffect(() => {
    dispatch(getBdEarning(bdId, startDate, endDate));
  }, [dispatch, bdId, startDate, endDate]);

  const handleCopy = async (link) => {
    if (admin?.isActive === false) return permissionError();
    try {
      await navigator.clipboard.writeText(link); // Use await to ensure clipboard action is completed.
      Toast("success", `Copy Success.`);
    } catch (error) {
      console.error("Copy failed:", error);
      Toast("error", "Copy Failed");
    }
  };

  const handleBack = () => {
    window.showAndroidToast();
  };

  const handleOpenRedeem = () => {
    if (admin?.isActive === false) return permissionError();
    dispatch({ type: OPEN_NEW_REDEEM_DIALOG });
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className="btn-gray rounded-pill px-2 py-1"
      style={{ border: "none" }}
      onClick={onClick}
      ref={ref}
    >
      {value}
    </button>
  ));

  const handleOpenHostHistory = (data) => {
    history.push("/bd/agencyHistory", { state: data?.agency });
  };

  return (
    <>
      <div class="page-container">
        <div class="page-content">
          <div class="main-wrapper">
            <div className="main-section p-2">
              <div
                className="row p-3 bg-white mb-2 "
                style={{
                  zIndex: "9",
                  position: "fixed",
                  width: "100%",
                  top: "0",
                }}
              >
                <div className="col-4 d-flex align-items-center">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => handleBack()}
                  >
                    <path
                      d="M1.18529 11.648L7.60196 18.0647C7.77484 18.2317 8.0064 18.3241 8.24674 18.322C8.48709 18.3199 8.717 18.2235 8.88696 18.0535C9.05692 17.8836 9.15332 17.6537 9.15541 17.4133C9.1575 17.173 9.0651 16.9414 8.89812 16.7685L4.04621 11.9166H20.1667C20.4098 11.9166 20.643 11.82 20.8149 11.6481C20.9868 11.4762 21.0834 11.2431 21.0834 11C21.0834 10.7568 20.9868 10.5237 20.8149 10.3518C20.643 10.1799 20.4098 10.0833 20.1667 10.0833H4.04621L8.89812 5.23137C8.98568 5.14681 9.05551 5.04566 9.10355 4.93382C9.15159 4.82198 9.17688 4.7017 9.17794 4.57999C9.179 4.45827 9.1558 4.33757 9.10971 4.22491C9.06362 4.11226 8.99555 4.00991 8.90949 3.92384C8.82342 3.83777 8.72107 3.7697 8.60842 3.72361C8.49576 3.67752 8.37506 3.65433 8.25334 3.65539C8.13163 3.65645 8.01134 3.68173 7.8995 3.72978C7.78767 3.77782 7.68652 3.84765 7.60196 3.9352L1.18529 10.3519C1.01344 10.5238 0.916904 10.7569 0.916904 11C0.916904 11.243 1.01344 11.4761 1.18529 11.648Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div className="col-5 ">
                  <p
                    className="text-dark fw-bold mb-0"
                    style={{ fontSize: "18px" }}
                  >
                    BD Center
                  </p>
                </div>
              </div>
              <div
                className="d-flex justify-content-between"
                style={{ marginTop: "65px" }}
              >
                <div className="bd-content">
                  <div className="d-flex">
                    <div>
                      <img
                        src={admin?.image ? admin?.image : male}
                        style={{
                          height: "40px",
                          width: "40px",
                          objectFit: "cover",
                          borderRadius: "50%",
                        }}
                        alt=""
                      />
                    </div>
                    <div className="ms-3">
                      <p
                        className="mb-0 fw-bold text-dark"
                        style={{ fontSize: "15px" }}
                      >
                        {admin?.name}
                      </p>
                      <p>Code : {admin?.bdCode}</p>
                    </div>
                  </div>
                </div>
                <div className="date-picker">
                    <DateRangePicker
                      initialSettings={{
                        startDate: undefined,
                        endDate: undefined,
                        ranges: {
                          All: [new Date("1970-01-01"), dayjs().toDate()],
                          Today: [dayjs().toDate(), dayjs().toDate()],
                          Yesterday: [
                            dayjs().subtract(1, "days").toDate(),
                            dayjs().subtract(1, "days").toDate(),
                          ],

                          "Last 7 Days": [
                            dayjs().subtract(6, "days").toDate(),
                            dayjs().toDate(),
                          ],
                          "Last 30 Days": [
                            dayjs().subtract(29, "days").toDate(),
                            dayjs().toDate(),
                          ],
                          "This Month": [
                            dayjs().startOf("month").toDate(),
                            dayjs().endOf("month").toDate(),
                          ],
                          "Last Month": [
                            dayjs()
                              .subtract(1, "month")
                              .startOf("month")
                              .toDate(),
                            dayjs()
                              .subtract(1, "month")
                              .endOf("month")
                              .toDate(),
                          ],
                        },
                        maxDate: new Date(),
                        singleDatePicker: false,
                        linkedCalendars: false,
                      }}
                      onApply={handleApply}
                    >
                      <input
                        type="text"
                        readOnly
                        placeholder="Select Date Range"
                        // onClick={handleInputClick}
                        className={`daterange float-right  mr-4  text-center`}
                        value={
                          (startDate === startAllDate &&
                            endDate === endAllDate) ||
                          (startDate === "All" && endDate === "All")
                            ? "Select Date Range"
                            : dayjs(startDate).format("MM/DD/YYYY") &&
                              dayjs(endDate).format("MM/DD/YYYY")
                            ? `${dayjs(startDate).format(
                                "MM/DD/YYYY"
                              )} - ${dayjs(endDate).format("MM/DD/YYYY")}`
                            : "Select Date Range"
                        }
                        style={{
                          fontWeight: 500,
                          cursor: "pointer",
                          background: "#F0F0F0",
                          color: "#000",
                          display: "flex",
                          width: "100%",
                          justifyContent: "end",
                          fontSize: "13px",
                          padding: "10px",
                          maxWidth: "226px",
                          borderRadius: "64px",
                          border: "1px solid transparent",
                          height: "48px !important",
                          outline: "none",
                        }}
                      />
                    </DateRangePicker>
                  </div>
              </div>
              <div className="income-detail">
                <div className=" d-flex  align-items-center mb-1">
                      <h6 className="mb-0 me-1 text-dark">Last Month Income:</h6>
                      <img
                        src={require("../assets/images/rcoin.png")}
                        style={{ height: "20px", width: "20px" }}
                        alt=""
                      />
                      <span
                        className="mb-0 ms-1 fw-bolder"
                        style={{ color: "#000", fontSize: "15px" }}
                      >
                        {admin?.lastSettlementCoin ? admin?.lastSettlementCoin : 0}
                      </span>
                    </div>
                <div className="d-flex justify-content-between align-items-center">

                  
                  <div>
                    <div className=" d-flex align-items-center">
                      <h6 className="mb-0 me-1 text-dark">Total Income:</h6>
                      <img
                        src={require("../assets/images/rcoin.png")}
                        style={{ height: "20px", width: "20px" }}
                        alt=""
                      />
                      <span
                        className="mb-0 ms-1 fw-bolder"
                        style={{ color: "#000", fontSize: "15px" }}
                      >
                        {summary?.hostCoin ? summary?.hostCoin : 0}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center text-dark fs-6 justify-content-center">
                    <Link to="/bd/income" className="text-dark">
                      Details
                      <img
                        src={leftArrow}
                        alt=""
                        height={10}
                        width={10}
                        style={{ marginLeft: "5px" }}
                      />
                    </Link>
                  </div>
                </div>
                <div className="inner-income d-flex align-items-center justify-content-between mt-2">
                  <div>
                    <span style={{ color: "#a7a7a7" }}>From Agency</span>
                    <div className="d-flex align-items-center justify-content-center">
                      <img
                        src={require("../assets/images/rcoin.png")}
                        style={{
                          height: "13px",
                          width: "13px",
                        }}
                        alt=""
                      />
                      <span
                        className="mb-0 ms-1 fw-bolder"
                        style={{ color: "#000", fontSize: "15px" }}
                      >
                        {summary?.totalCoin ? summary?.totalCoin : 0}
                      </span>
                    </div>
                  </div>
                  &nbsp; <span className="text-dark fw-bold h4 m-0">+</span>
                  <div>
                    <span style={{ color: "#a7a7a7" }}>From target</span>
                    <div className="d-flex align-items-center justify-content-center">
                      <img
                        src={require("../assets/images/rcoin.png")}
                        style={{ height: "20px", width: "20px" }}
                        alt=""
                      />
                      <span
                        className="mb-0 ms-1 fw-bolder "
                        style={{ color: "#000", fontSize: "15px" }}
                      >
                        {summary?.bonus ? summary?.bonus : 0}
                      </span>
                    </div>
                  </div>
                  <div>
                    {/* <div>
                      <button
                        className="btn-primary mt-2 px-2 py-1 rounded-pill"
                        style={{ border: "none", backgroundColor: "#0092ff" }}
                        onClick={handleOpenRedeem}
                      >
                        Add Redeem
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="agency-detail mt-3">
                <div className="d-flex" style={{ columnGap: "20px" }}>
                  <h4
                    className="text-dark fw-bold pt-2 "
                    style={{
                      fontSize: "16px",
                      borderBottom:
                        type === "agency" ? "2px solid black" : "none",
                    }}
                    onClick={() => setType("agency")}
                  >
                    Agency
                  </h4>

                  <h4
                    className="text-dark fw-bold pt-2"
                    style={{
                      fontSize: "16px",
                      borderBottom:
                        type === "target" ? "2px solid black" : "none",
                    }}
                    onClick={() => setType("target")}
                  >
                    Target
                  </h4>
                </div>

                {type === "agency" && (
                  <div className="agency-invitation mt-4 p-3 bg-white">
                    <div className="d-flex align-items-center">
                      <i
                        className="fas fa-user"
                        style={{ color: "#F29100", fontSize: "24px" }}
                      ></i>

                      <div className="ms-2">
                        <div
                          className="mb-0 text-dark"
                          style={{ fontSize: "13px", fontWeight: "500" }}
                        >
                          Agency invitation
                        </div>
                        <span className="d-flex align-items-center">
                          <Link to={`/bd/agencyList`} className="text-dark fw-bold">
                            Agency list : {agency?.length}
                            <img
                              src={leftArrow}
                              alt=""
                              height={10}
                              width={10}
                              style={{ marginLeft: "5px" }}
                            />
                          </Link>
                        </span>
                      </div>
                      <div className="ms-auto">
                        <button
                          className="btn-primary rounded-pill"
                          onClick={() => handleCopy(admin?.inviteLink)}
                          style={{
                            border: "1px solid #0092ff",
                            backgroundColor: "white",
                            color: "#0092ff",
                          }}
                        >
                          Invitation
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {type === "target" && (
                  <div className="agency-detail mt-3">
                    {agencyCommission?.length > 0 ? (
                      <table
                        className="table border"
                        style={{
                          borderCollapse: "collapse",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          borderRadius: "12px",
                          overflow: "hidden",
                        }}
                      >
                        <thead>
                          <tr style={{ backgroundColor: "#f8f9fa" }}>
                            <th
                              scope="col"
                              style={{ borderBottom: "none", fontWeight: 800 }}
                            >
                              Agency Level
                            </th>

                            <th
                              scope="col"
                              style={{ borderBottom: "none", fontWeight: 800 }}
                            >
                              Agency Income
                            </th>
                            <th
                              scope="col"
                              style={{ borderBottom: "none", fontWeight: 800 }}
                            >
                              Bonus/Agency
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {agencyCommission?.slice(0,4).map((data, index) =>{
                                                      let formattedUpperCoin;
                                                      if (data?.upperCoin >= 1000000) {
                                                        formattedUpperCoin =
                                                          (data?.upperCoin / 1000000).toFixed() + "M";
                                                      } else if (data?.upperCoin >= 1000) {
                                                        formattedUpperCoin =
                                                          (data?.upperCoin / 1000).toFixed() + "K";
                                                      } else {
                                                        formattedUpperCoin = data?.upperCoin.toString();
                                                      }
                            return (
                             <tr
                               key={index}
                               style={{
                                 backgroundColor: "#ffffff",
                                 boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                 marginBottom: "10px",
                                 borderRadius: "12px",
                               }}
                             >
                               <td
                                 className="align-middle d-flex align-items-center"
                                 style={{ columnGap: "10px", border: "none" }}
                               >
                                 <h6
                                   className="mb-0 fw-bold text-dark"
                                   style={{ fontSize: "13px", columnGap: "5px" }}
                                 ></h6>
                                 <img
                                   src={data?.image ? data?.image : male}
                                   style={{
                                     height: "30px",
                                     width: "30px",
                                     objectFit: "cover",
                                     borderRadius: "50%",
                                   }}
                                   alt=""
                                 />
                               </td>
 
                               <td
                                 className="align-middle"
                                 style={{
                                   border: "none",
                                 }}
                               >
                                 <span
                                   className="fw-bolder"
                                   style={{ fontSize: "11px" }}
                                 >
                                   <img
                                     src={require("../assets/images/rcoin.png")}
                                     style={{
                                       height: "13px",
                                       width: "13px",
                                     }}
                                     alt=""
                                   />
                                   {formattedUpperCoin
                                     ? formattedUpperCoin
                                     : 0}
                                 </span>
                               </td>
                               <td
                                 className="align-middle"
                                 style={{
                                   border: "none",
                                 }}
                               >
                                 <span
                                   className=""
                                   style={{
                                     fontSize: "11px",
                                     color: "#ff8300",
                                     fontWeight: 600,
                                   }}
                                 >
                                   <img
                                     src={require("../assets/images/rcoin.png")}
                                     style={{
                                       height: "13px",
                                       width: "13px",
                                     }}
                                     alt=""
                                   />
                                   {data?.amount ? data?.amount.toFixed(0) : 0}(
                                   {`${
                                     "$" +
                                     data?.amount / setting?.rCoinForCashOut
                                   } `}
                                   )
                                 </span>
                               </td>
                             </tr>
                           )
                          }
                          )}
                        </tbody>
                      </table>
                    ) : (
                      <div className="d-flex justify-content-center align-items-center mt-5">
                        <span>No data found.</span>
                      </div>
                    )}
                  </div>
                )}

                <div className="agency-invitation mt-3 bg-white p-3">
                  <div className=" d-flex align-items-center">
                    <p
                      className="mb-0 me-1 fw-bold text-dark"
                      style={{ fontSize: "13px" }}
                    >
                      Your Income:
                    </p>
                    <img
                      src={require("../assets/images/rcoin.png")}
                      style={{ height: "20px", width: "20px" }}
                      alt=""
                    />
                    <span
                      className="mb-0 ms-1 fw-bolder"
                      style={{ color: "#000", fontSize: "15px" }}
                    >
                      {admin?.currentCoin ? admin?.currentCoin.toFixed(2) : 0}
                    </span>
                  </div>
                  <div className="d-flex align-items-center">
                    <div>
                      {" "}
                      <span style={{ color: "#a7a7a7" }}>
                        Total Agency Income
                      </span>
                      <div className="d-flex align-items-center">
                        <img
                          src={require("../assets/images/rcoin.png")}
                          style={{ height: "20px", width: "20px" }}
                          alt=""
                        />
                        <span
                          className="mb-0 ms-1 fw-bolder"
                          style={{ color: "#000", fontSize: "15px" }}
                        >
                          {admin?.currentAgencyCoin
                            ? admin?.currentAgencyCoin.toFixed(2)
                            : 0}
                        </span>
                      </div>
                    </div>

                    <div className="mx-3">
                      <span className="text-dark fs-3 d-blck">×</span>
                    </div>

                    <div className="ml-5">
                      <span style={{ color: "#a7a7a7" }}>Commission Rate</span>
                      <div className="d-flex align-items-center">
                        <span
                          className="mb-0 ms-1 fw-bolder"
                          style={{ color: "#000", fontSize: "15px" }}
                        >
                          {setting?.bdCommissionPercent} %
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* <div className="mt-3">
                    <div className="d-flex align-items-center justify-content-between">
                      {agencyCommission &&
                        agencyCommission.map((data) => {
                          return <span>{data?.amountPercentage + "%"}</span>;
                        })}
                    </div>
                    <div
                      style={{ height: "5px", backgroundColor: "#ffbcff" }}
                    ></div>
                    <div className="d-flex align-items-center justify-content-between">
                      {agencyCommission &&
                        agencyCommission.map((data) => {
                          let formattedUpperCoin;
                          if (data?.upperCoin >= 1000000) {
                            formattedUpperCoin =
                              (data?.upperCoin / 1000000).toFixed() + "M";
                          } else if (data?.upperCoin >= 1000) {
                            formattedUpperCoin =
                              (data?.upperCoin / 1000).toFixed() + "K";
                          } else {
                            formattedUpperCoin = data?.upperCoin.toString();
                          }
                          return (
                            <div className="d-flex align-items-center">
                              <img
                                src={require("../assets/images/rcoin.png")}
                                style={{ height: "15px", width: "15px" }}
                                alt=""
                              />
                              <span
                                className="mb-0 ms-1 fw-bolder"
                                style={{ color: "#000", fontSize: "12px" }}
                              >
                                {formattedUpperCoin ? formattedUpperCoin : 0}
                              </span>
                            </div>
                          );
                        })}
                    </div>
                  </div> */}
                  {/* <div
                    className="agency-invitation p-3 mt-2"
                    style={{ backgroundColor: "#ffeaff", borderRadius: "30px" }}
                  >
                    <div className="d-flex">
                      <div className="my-auto">
                        <i
                          class="fa-solid fa-chevron-up text-white p-2"
                          style={{
                            borderRadius: "50%",
                            backgroundColor: "#e800ad",
                          }}
                        ></i>
                      </div>
                      <div
                        className=" ms-3"
                        style={{ verticalAlign: "center" }}
                      >
                        <p
                          className="mb-0  text-dark d-flex align-items-center"
                          style={{ fontSize: "15px", fontWeight: "500" }}
                        >
                          <span style={{ fontSize: "13px" }}>
                            Earnings current week :
                          </span>
                          <img
                            src={require("../assets/images/rcoin.png")}
                            style={{ height: "20px", width: "20px" }}
                            alt=""
                          />
                          <span>
                            {admin?.currentCoin
                              ? admin?.currentCoin.toFixed(2)
                              : 0}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div> */}
                </div>

                <div className="d-flex align-items-center justify-content-between mt-3">
                  <h4
                    className="text-dark fw-bold pt-2 "
                    style={{ fontSize: "16px" }}
                  >
                    Agency details
                  </h4>
                  {/* <div
                    className="d-flex align-items-center text-dark justify-content-center"
                    style={{ fontSize: "10px" }}
                  >
                    <Link to="/bd/allAgency" className="text-dark">
                      Show More
                      <img
                        src={leftArrow}
                        alt=""
                        height={10}
                        width={10}
                        style={{ marginLeft: "5px" }}
                      />
                    </Link>
                  </div> */}
                </div>

                {agency?.length > 0 ? (
                  <>
                    {agency?.map((data, index) => {
                      return (
                        <>
                       <div className="agency-invitation mt-3 bg-white p-3">
                                    <div className="d-flex">
                                      <div>
                                        <img
                                          src={
                                            data?.agency?.image
                                              ? data?.agency?.image
                                              : male
                                          }
                                          style={{
                                            height: "30px",
                                            width: "30px",
                                            objectFit: "cover",
                                            borderRadius: "50%",
                                          }}
                                          alt="agency-image"
                                        />
                                      </div>
                                      <div className="ms-3">
                                        <div className="d-flex">
                                          <h6
                                            className="mb-0 fw-bold text-dark"
                                            style={{ fontSize: "13px" }}
                                          >
                                            {data?.agency?.name}
                                          </h6>
                                          <button
                                            className="btn-primary rounded ms-2"
                                            style={{
                                              border: "none",
                                              fontSize: "9px",
                                              backgroundColor: "#0092ff",
                                            }}
                                          >
                                            Agency
                                          </button>
                                        </div>
                                        <div className="d-flex">
                                          <p className="mb-0">
                                            ID : {data?.agency?.uniqueId}
                                          </p>
                                          <p className="mb-0 ms-2">
                                            Code : {data?.agency?.agencyCode}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                      <div className=" d-flex align-items-center mt-2">
                                        <p
                                          className="mb-0 me-1 text-dark"
                                          style={{ fontSize: "11px" }}
                                        >
                                          Agency Income:
                                        </p>
                                        <img
                                          src={require("../assets/images/rcoin.png")}
                                          style={{
                                            height: "13px",
                                            width: "13px",
                                          }}
                                          alt=""
                                        />
                                        <span
                                          className="mb-0 ms-1 fw-bolder"
                                          style={{
                                            color: "#ff8300",
                                            fontSize: "11px",
                                          }}
                                        >
                                          {data?.agency
                                            ? data?.agency?.currentCoin.toFixed(
                                                2
                                              )
                                            : 0}
                                        </span>
                                      </div>
                                      <div
                                        className="d-flex align-items-center text-dark justify-content-center"
                                        style={{
                                          fontSize: "10px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => handleOpenHostHistory(data)}
                                      >
                                        History
                                        <img
                                          src={leftArrow}
                                          alt=""
                                          height={10}
                                          width={10}
                                          style={{ marginLeft: "5px" }}
                                        />
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        boxShadow:
                                          "0 4px 8px rgba(0, 0, 0, 0.1)",
                                        padding: "10px",
                                        borderRadius : "8px",
                                        marginLeft : "10px"
                                      }}
                                      className="mt-3 creator"
                                    >
                                      <div className="d-flex justify-content-between mt-2 ">
                                        <h6 className="text-dark mb-1">
                                          Creator Income
                                        </h6>
                                        <h6 className="text-dark mb-1">
                                          Valid Creator
                                        </h6>
                                        <h6 className="text-dark mb-1">
                                          New Creator
                                        </h6>
                                      </div>

                                      <div className="d-flex justify-content-between">
                                        <p className="color-gray">
                                        <img
                                          src={require("../assets/images/rcoin.png")}
                                          style={{
                                            height: "13px",
                                            width: "13px",
                                          }}
                                          alt=""
                                        />{data?.totalCurrentCoin}
                                        </p>
                                        <p className="color-gray">
                                          {data?.hostCount}
                                        </p>
                                        <p className="color-gray">{data?.agency?.newCreator}</p>
                                      </div>
                                    <p className="color-gray" style={{
                                      fontSize : "12px"
                                    }}>Phone  : + {data?.agency?.mobile}</p>
                                    </div>

                                  </div>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <div className="d-flex justify-content-center align-items-center my-4">
                      <span>No data found.</span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Admin;
