import axios from "axios";
import { Toast } from "../../util/Toast";

import { GET_AGENCY_STATUS_WISE, GET_SELLER } from "./types";
import { key } from "../../util/Config";

export const getProfile = (bdId) => (dispatch) => {
  axios
    .get(`bd/getbdProfile?bdId=${bdId}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: GET_SELLER, payload: res.data?.data });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log("error", error.message);
    });
};

export const getStatusWiseAgency = (bdId, type) => (dispatch) => {
  axios
    .get(`bd/bdWiseAgencyTypeWise?bdId=${bdId}&type=${type}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: GET_AGENCY_STATUS_WISE, payload: res?.data?.data });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log("error", error.message);
    });
};


