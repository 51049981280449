import axios from "axios";
import { Toast } from "../../util/Toast";

import { GET_BD, INVITE_AGENCY } from "./bd.type";

export const getAgencyCommission = (code) => (dispatch) => {
  axios
    .get(`bd/getBdForAgency?bdCode=${code}`)
    .then((res) => {
      if (res?.data?.status) {
        dispatch({ type: GET_BD, payload: res?.data });
      } else {
        Toast("error", res?.data?.message);
      }
    })
    .catch((error) => {
      console.log(error);
      Toast("error", error.message);
    });
};

export const InviteAgency = (data) => (dispatch) => {
  axios
    .post(`agency/store`, data)
    .then((res) => {
      if (res?.data?.status) {
        dispatch({ type: INVITE_AGENCY, payload: res?.data });
        Toast("success", "Request sent successfully");
        
      } else {
        Toast("error", res?.data?.message);
      }
    })
    .catch((error) => {
      console.log(error);
      Toast("error", error.message);
    });
};
