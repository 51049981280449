import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { InviteAgency, getAgencyCommission } from "../store/bd/bd.action";
import { useDispatch, useSelector } from "react-redux";
const AgencyForm = () => {
  const { id } = useParams();

  const { bdProfile } = useSelector((state) => state.bd);

  const [name, setName] = useState();
  const [uniqueId, setUniqueId] = useState();
  const [contact, setContact] = useState();
  const [agencyCode, setAgencyCode] = useState();
  const [error, setError] = useState({
    uniqueId: "",
    contact: "",
    name: "",
  });
  useEffect(() => {
    if (id) {
      localStorage.setItem("bdCode", id);
    }
  }, [id]);

  console.log("bdProfile", bdProfile);
  const dispatch = useDispatch();
  useEffect(() => {
    const bdId = localStorage.getItem("bdCode");
    const bdIds = id || bdId;
    dispatch(getAgencyCommission(bdIds));
  }, [id, dispatch]);

  const createCode = () => {
    const randomChars = "0123456789";
    let code_ = "";
    for (let i = 0; i < 8; i++) {
      code_ += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
      setAgencyCode(code_);
    }
    if (!code_) {
      return setError({
        ...error,
        agencyCode: "Code can't be a blank!",
      });
    } else {
      return setError({
        ...error,
        agencyCode: "",
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name || !uniqueId || !contact || !agencyCode) {
      let error = {};

      if (!name) error.name = "Name is required";
      if (!uniqueId) error.uniqueId = "uniqueId is required";
      if (!contact) error.contact = "Whatsapp Number is required";
      if (!agencyCode) error.agencyCode = "AgencyCode is required";

      return setError({ ...error });
    } else {
      let data = {
        bdCode: bdProfile?.bdCode,
        name: name,
        agencyCode: agencyCode,
        uniqueId: uniqueId,
        mobile: contact,
      };
      dispatch(InviteAgency(data));
      setName("");
      setAgencyCode("");
      setUniqueId("");
      setContact("");
    }
  };

  return (
    <div>
      <div className="mainAdminForm vh-100 vw-100">
        <div className="bd-form ">
          <div className="bdProfile p-3">
            <div className="text-center pt-4 fs-5 text-dark fw-bold">
              Boomer live agent invitation
            </div>
            <div className="bdProfileImg pt-4 rounded-4">
              <img src={bdProfile?.image} alt="" />
              <div className="bdProfileName">
                <h3 className="text-center mt-3 text-capitalize">
                  {bdProfile?.name}
                </h3>
                <h6 className="text-center text-dark mt-3">
                  {"Bd code:" + bdProfile?.bdCode}
                </h6>
                <div
                  className="text-center "
                  style={{ color: "#ff68e7", fontSize: "15px" }}
                >
                  invite you to be an agent
                </div>
              </div>
            </div>

            <form className="agencyForm mt-3">
              <div className="p-3">
                <div class="mt-3 ">
                  <div class="formLabel">Name</div>
                  <div className="formField">
                    <input
                      type="text"
                      class="agencyFormField"
                      id="name"
                      placeholder="Name"
                      required
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            name: "Please enter name!",
                          });
                        } else {
                          return setError({
                            ...error,
                            name: "",
                          });
                        }
                      }}
                    />
                  </div>
                  <div class="mt-2 ml-2 mb-3">
                    {error.name && (
                      <div class="pl-1 text-left pb-1">
                        <span className="text-red font-size-lg">
                          {error.name}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div class="mt-3 ">
                  <div class="formLabel">Bd code</div>
                  <div className="formField">
                    <input
                      type="number"
                      class="agencyFormField"
                      id="bdCode"
                      placeholder="Bd code"
                      readOnly
                      disabled
                      value={bdProfile?.bdCode}
                    />
                  </div>
                </div>

                <div class="mt-3 ">
                  <div class="formLabel">Unique Id</div>
                  <div className="formField">
                    <input
                      type="number"
                      class="agencyFormField"
                      id="uniqueId"
                      placeholder="Unique Id"
                      required
                      value={uniqueId}
                      onChange={(e) => {
                        setUniqueId(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            uniqueId: "Please enter uniqueId!",
                          });
                        } else {
                          return setError({
                            ...error,
                            uniqueId: "",
                          });
                        }
                      }}
                    />
                  </div>
                  <div class="mt-2 ml-2 mb-3">
                    {error.uniqueId && (
                      <div class="pl-1 text-left pb-1">
                        <span className="text-red font-size-lg">
                          {error.uniqueId}
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                <div class="mt-3 ">
                  <div class="formLabel">Whatsapp Number</div>
                  <div className="formField">
                    <input
                      type="number"
                      class="agencyFormField"
                      id="contact"
                      placeholder="Whatsapp Number"
                      required
                      value={contact}
                      onChange={(e) => {
                        setContact(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            contact: "Please enter whatsapp number!",
                          });
                        } else {
                          return setError({
                            ...error,
                            contact: "",
                          });
                        }
                      }}
                    />
                  </div>
                  <div class="mt-2 ml-2 mb-3">
                    {error.contact && (
                      <div class="pl-1 text-left pb-1">
                        <span className="text-red font-size-lg">
                          {error.contact}
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                <div class="mt-3 ">
                  <div class="formLabel">Agency Code</div>
                  <div className="formField d-flex">
                    <input
                      type="number"
                      class="agencyFormField"
                      id="agencyCode"
                      placeholder="Agency Code"
                      required
                      readOnly
                      value={agencyCode}
                      onChange={(e) => {
                        setAgencyCode(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            agencyCode: "Please enter whatsapp number!",
                          });
                        } else {
                          return setError({
                            ...error,
                            agencyCode: "",
                          });
                        }
                      }}
                    />
                    <div class="col-md-3 pl-0 d-flex justify-content-end align-items-center">
                      <button
                        type="button"
                        class="btn btn-info"
                        style={{
                          borderRadius: 5,
                          fontSize: "14px",
                          padding: "8px",
                        }}
                        onClick={createCode}
                      >
                        Auto
                      </button>
                    </div>
                  </div>
                  <div class="mt-2 ml-2 mb-3">
                    {error.agencyCode && (
                      <div class="pl-1 text-left pb-1">
                        <span className="text-red font-size-lg">
                          {error.agencyCode}
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                <button className="submitForm" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgencyForm;
