export const GET_USER = "GET_USER";
export const BLOCK_UNBLOCK_SWITCH = "BLOCK_UNBLOCK_SWITCH";
export const GET_POST = "GET_POST";
export const GET_COMMENT = "GET_COMMENT";
export const GET_LIKE = "GET_LIKE";
export const GET_HISTORY = "GET_HISTORY";

export const EDIT_COIN = "EDIT_COIN";
export const GET_FOLLOWERS_FOLLOWING_LIST = "GET_FOLLOWERS_FOLLOWING_LIST";
export const GET_COIN_PLAN_HISTORY = "GET_COIN_PLAN_HISTORY";
export const GET_VIP_PLAN_HISTORY = "GET_VIP_PLAN_HISTORY";
export const GET_VIDEO = "GET_VIDEO";
