import {
  BLOCK_UNBLOCK_SWITCH,
  GET_USER,
  GET_HISTORY,
  GET_LIKE,
  GET_POST,
  EDIT_COIN,
  GET_COMMENT,
  GET_FOLLOWERS_FOLLOWING_LIST,
  GET_VIDEO,
  GET_COIN_PLAN_HISTORY,
  GET_VIP_PLAN_HISTORY,
} from "./types";

const initialState = {
  user: [],
  male: 0,
  female: 0,
  totalUser: 0,
  activeUser: 0,
  history: [],
  totalHistoryUser: 0,
  income: 0,
  outgoing: 0,
  totalCallCharge: 0,
  liveStreamingIncome: 0,
  post: [],
  comment: [],
  video: [],
  totalVideo: 0,
  like: [],
  followersFollowing: [],
  totalPost: 0,
  coinHistory: [],
  totalCoinPlan: 0,
  vipHistory: [],
  totalVipPlan: 0,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        user: action.payload.user,
        male: action.payload.male,
        female: action.payload.female,
        totalUser: action.payload.total,
        activeUser: action.payload.activeUser,
      };

    case BLOCK_UNBLOCK_SWITCH:
      return {
        ...state,
        user: state.user.map((user) => {
          if (user._id === action.payload._id)
            return {
              ...user,
              isBlock: action.payload.isBlock,
            };
          else return user;
        }),
      };

    case GET_HISTORY:
      return {
        ...state,
        history: action.payload.history,
        totalHistoryUser: action.payload.total,
        income: action.payload.incomeTotal,
        outgoing: action.payload.outgoingTotal,
        totalCallCharge: action.payload.totalCallCharge,
        liveStreamingIncome: action.payload.income,
      };
    case EDIT_COIN:
      return {
        ...state,
        user: state.user.map((user) => {
          if (user._id === action.payload.id) return action.payload.data;
          else return user;
        }),
      };
    case GET_POST:
      return {
        ...state,
        post: action.payload.post,
        totalPost: action.payload.total,
      };
    case GET_COMMENT:
      return {
        ...state,
        comment: action.payload,
      };
    case GET_LIKE:
      return {
        ...state,
        like: action.payload,
      };
    case GET_FOLLOWERS_FOLLOWING_LIST:
      return {
        ...state,
        followersFollowing: action.payload,
      };
    case GET_VIDEO:
      return {
        ...state,
        video: action.payload.video,
        totalVideo: action.payload.total,
      };
    case GET_COIN_PLAN_HISTORY:
      return {
        ...state,
        coinHistory: action.payload.history,
        totalCoinPlan: action.payload.total,
      };
      case GET_VIP_PLAN_HISTORY:
        return {
          ...state,
          vipHistory: action.payload.history,
          totalVipPlan: action.payload.total,
        };
    default:
      return state;
  }
};

export default userReducer;
