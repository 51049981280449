import {
    GET_BD,
} from "./bd.type";

const initialState = {
    bdProfile: {},
    bd:[],
    total: 0,
};

const bdReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_BD:
            return {
                ...state,
                bdProfile: action.payload.data,
            };
        default:
            return state;
    }
};

export default bdReducer;
