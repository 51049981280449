// ADMIN HISTORY

export const GET_ADMIN_HISTORY = "GET_ADMIN_HISTORY";
export const GET_AGENCY_HISTORY = "GET_AGENCY_HISTORY";
export const GET_ADMIN_EARNING = "GET_ADMIN_EARNING";

// USER HISTORY
export const GET_USER_HISTORY = "GET_USER_HISTORY";



export const GET_ADMIN_CASHOUT = "GET_ADMIN_CASHOUT";